<template>
  <a-page-header
    style="border: 1px solid rgb(235, 237, 240)"
    :title="$t('recette.ajouterPaiement')"
    class="card"
    @back="() => $router.go(-1)"
  >
    <div class="card-header row">
      <div class="col-md-6">
        <a-select
          show-search
          style="width: 100%"
          option-filter-prop="children"
          :placeholder="$t('recette.selectionnerParent')"
          :filter-option="filterOption"
          allow-clear
          @select="selectParent"
        >
          <a-select-option
            :value="item._id"
            v-for="item in parentList"
            :key="item._id"
          >
            Mr.{{ item.father.firstName }} ET Mm.{{ item.mother.firstName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-6">
        <a-select
          show-search
          style="width: 100%"
          option-filter-prop="children"
          :placeholder="$t('recette.selectionnerEnfant')"
          :filter-option="filterOption"
          allow-clear
          @select="showStudentPayment"
        >
          <a-select-option
            v-for="item in Active_studentList"
            :value="item.student._id"
            :key="item.student._id"
          >
            {{ item.student.firstName }} {{ item.student.lastName }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div class="card-body" v-if="activeStudentPaymentList.active">
      <table class="table table-striped col-md-12">
        <thead>
          <th>{{ $t("recette.typePaiement") }}</th>
          <th>{{ $t("recette.titre") }}</th>
          <th></th>
        </thead>
        <tbody>
          <tr
            v-for="payment in activeStudentPaymentList.data"
            :key="payment._id"
          >
            <td>
              {{
                payment.type == "activity"
                  ? $t("recette.activite")
                  : $t("menu.scolarite")
              }}
            </td>
            <td>{{ payment.name }}</td>
            <td>
              <a-button icon="eye" @click="showModal(payment)">{{
                $t("action.afficher")
              }}</a-button>
            </td>
          </tr>
        </tbody>
      </table>
      <a-modal
        :width="976"
        v-model="visableModal"
        @cancel="cancelModel"
        :footer="false"
      >
        <payment-validator
          :data="modalData"
          :receiptTypes="receiptTypes"
          :key="reloadCount"
          :idParent="parentId"
          v-on:reload="reloadComp"
        >
        </payment-validator>
      </a-modal>
    </div>
  </a-page-header>
</template>
<script>
/* eslint-disable */
import { PageHeader, Descriptions } from "ant-design-vue";
import apiClient from "@/services/axios";
import paymentValidator from "@/views/eleves/profile/components/paymentValidator";

const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months)
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    return "payed";
  }
};

const activityFeesFormater = (activityFees) => {
  const activity = activityFees.activity[0];
  return {
    _id: activityFees._id,
    student: activityFees.student[0],
    classRoom: activityFees.classRoom[0],
    parent: activityFees.parent[0],
    type_id: activity._id,
    name: activity.designation,
    once: activity.once,
    inscription: activityFees.inscription,
    months: activityFees.months,
    reduction: activityFees.reduction,
    status: getStatus({
      inscription: activityFees.inscription,
      once: activity.once,
      months: activityFees.months,
    }),
  };
};

const classRoomPaymentsFormater = (classRoomPayment) => {
  const classRoom = classRoomPayment.classRoom[0];
  return {
    _id: classRoomPayment._id,
    student: classRoomPayment.student[0],
    classRoom: classRoom,
    parent: classRoomPayment.parent[0],
    type_id: classRoom._id,
    name: classRoom.name,
    inscription: classRoomPayment.inscription,
    months: classRoomPayment.months,
    reduction: classRoomPayment.reduction,
    status: getStatus({
      inscription: classRoomPayment.inscription,
      once: false,
      months: classRoomPayment.months,
    }),
  };
};

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];

  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};

const getParentList = (students) => {
  let parents = [];
  let parentsIDs = [];
  students.forEach((student) => {
    if (student.parent) {
      if (!parentsIDs.includes(student.parent._id)) {
        parents.push(student.parent);
        parentsIDs.push(student.parent._id);
      }
    } else {
      console.log(student);
    }
  });
  return parents;
};

const getActiveStudentList = (row) => {
  let students = [];
  let studentsIDs = [];
  row.forEach((payment) => {
    if (payment.student) {
      //this condition was added due to student deleted bug
      if (!studentsIDs.includes(payment.student._id)) {
        students.push(payment);
        studentsIDs.push(payment.student._id);
      }
    }
  });
  return students;
};

export default {
  name: "addPayment",
  components: {
    "a-page-header": PageHeader,
    "payment-validator": paymentValidator,
    "a-descriptions": Descriptions,
  },
  async created() {
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" }, //schoolarYear: this.settings.activeSchoolarYear
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    apiClient
      .post("/classrooms/filter", {
        query: {}, //schoolarYear: this.settings.activeSchoolarYear
        aggregation: {
          $project: {
            name: 1,
            inscription: 1,
            monthly: 1,
          },
        },
      })
      .then((res) => (this.classRoomList = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    apiClient
      .post("/activities/filter", {
        query: {}, //schoolarYear: this.settings.activeSchoolarYear
        aggregation: [
          {
            $lookup: {
              from: "services",
              localField: "service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $project: {
              once: 1,
              service: { name: 1 },
              inscription: 1,
              monthly: 1,
              start: 1,
              end: 1,
              designation: 1,
              order: 1,
            },
          },
        ],
      })
      .then((res) => (this.activitiesList = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    await apiClient
      .post("/students/activity/payment/filter", {
        query: {
          status: "active",
        },
        aggregation: [
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "activities",
              localField: "activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "student.classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $project: {
              activity: {
                _id: 1,
                once: 1,
                designation: 1,
              },
              classRoom: {
                _id: 1,
                name: 1,
              },
              student: {
                firstName: 1,
                lastName: 1,
                _id: 1,
              },
              parent: {
                _id: 1,
                father: 1,
                mother: 1,
              },
              inscription: 1,
              months: 1,
              reduction: 1,
            },
          },
        ],
      })
      .then((res) =>
        res.data.map((elem) => {
          const temp = activityFeesFormater(elem);
          if (temp.status == "impayed")
            this.studentList.push({
              ...temp,
              type: "activity",
            });
        })
      )
      .catch((e) =>
        this.$message.error("Aucun inscription au activité est trouvé :")
      );
    await apiClient
      .post("/classrooms/payment/filter", {
        query: {
          student: this.studentId,
        },
        aggregation: [
          {
            $lookup: {
              from: "classrooms",
              localField: "classRoom",
              foreignField: "_id",
              as: "classRoom",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $project: {
              _id: 1,
              months: 1,
              inscription: 1,
              classRoom: {
                _id: 1,
                name: 1,
              },
              student: {
                firstName: 1,
                lastName: 1,
                _id: 1,
              },
              parent: {
                father: 1,
                mother: 1,
                _id: 1,
              },
              inscription: 1,
              months: 1,
              reduction: 1,
            },
          },
        ],
      })
      .then((res) =>
        res.data.map((elem) => {
          const temp = classRoomPaymentsFormater(elem);
          if (temp.status == "impayed")
            this.studentList.push({
              ...temp,
              type: "classRoom",
            });
        })
      )
      .catch((e) =>
        this.$message.error("Aucun inscription au scolarité est trouvé :")
      );
    this.parentList = getParentList(this.studentList);
    this.rowStudentList = getActiveStudentList(this.studentList);
    this.Active_studentList = [...this.rowStudentList];
  },
  data() {
    return {
      modalData: {},
      visableModal: false,
      parentList: [],
      filters: { classe: false, classe_val: null, type: false, type_val: null },
      receiptTypes: [],
      classRoomList: [],
      activitiesList: [],
      studentList: [],
      rowStudentList: [],
      Active_studentList: [],
      selectedStudent: { active: false, val: null },
      selectedMode: { active: false, val: null },
      activeStudentPaymentList: { active: false, data: [] },
      disablePayment: true,
      paymentDetails: null,
      paymentTitle: "",
      reloadCount: 0,
      parentId: null,
    };
  },
  methods: {
    cancelModel() {
      this.visableModal = false;
      this.reloadCount++;
    },
    getMonthName,
    reloadComp() {
      this.$emit("reload");
    },
    showModal(val) {
      console.log(val);
      this.modalData = {
        ...val,
        typeData: this.getoney(val.type_id, val.type),
      };
      this.visableModal = true;
    },
    selectParent(val) {
      this.parentId = val;
      this.Active_studentList = this.rowStudentList.filter(
        (student) => student.parent._id == val
      );
    },
    showStudentPayment(studentId) {
      this.activeStudentPaymentList.data = this.studentList.filter(
        (payment) => payment.student && payment.student._id == studentId
      );
      this.activeStudentPaymentList.active = true;
    },
    getUrl(type, paymentType) {
      const types = paymentType == "Receipt" ? "receipt" : "bill";
      const to = type == "classRoom" ? "classroom" : "activity";
      return `/students/${to}/${types}`;
    },
    validPayment(paymentType, what) {
      const paymentDetails = this.paymentDetails;
      paymentType = paymentType.key;
      const payment = this.selectedStudent.val;
      const paymentMode = this.selectedMode.val;
      let data = what.monthKey
        ? {
            onInscription: what.onInscription,
            date: Date.now(),
            receiptType: paymentMode,
            months: what.monthKey,
          }
        : {
            onInscription: what.onInscription,
            date: Date.now(),
            receiptType: paymentMode,
          };
      if (payment.type == "classRoom") data.classRoomPayment = payment._id;
      else data.activityFees = payment._id;

      apiClient
        .post(this.getUrl(payment.type, paymentType), data)
        .then((res) => {
          this.$message.success("Paiment ajouté");
          this.studentList = this.studentList.map((elem) => {
            if (elem._id == payment._id) {
              let newElem = { ...elem };
              if (what.onInscription) newElem.inscription = true;
              else {
                Object.keys({ ...elem.months }).forEach((key) => {
                  if (key == what.monthKey) {
                    newElem.months[key] = true;
                    console;
                  }
                });
              }
              return newElem;
            } else return elem;
          });
          this.Active_studentList = this.Active_studentList.map((elem) => {
            if (elem._id == payment._id) {
              let newElem = { ...elem };
              if (what.onInscription) newElem.inscription = true;
              else {
                Object.keys({ ...elem.months }).forEach((key) => {
                  if (key == what.monthKey) {
                    newElem.months[key] = true;
                  }
                });
              }
              return newElem;
            } else return elem;
          });
        })
        .catch((e) =>
          this.$message.warning("Selectionner le type de paiement!")
        );
    },
    getoney(_id, type) {
      if (type == "activity")
        return this.activitiesList.filter((el) => el._id == _id)[0];
      else return this.classRoomList.filter((el) => el._id == _id)[0];
    },
    selectStudent(v) {
      const student = this.Active_studentList.filter((e) => e._id == v)[0];
      this.selectedStudent = {
        active: true,
        val: student,
      };
      this.paymentDetails = this.getoney(student.type_id, student.type);
      this.paymentTitle = "Paiements pour l";
      if (student.type == "activity")
        this.paymentTitle =
          "Paiements pour l'activité: " +
          this.paymentDetails.designation +
          `(service: ${this.paymentDetails.service[0].name})`;
      else
        this.paymentTitle =
          "Paiements pour la scolarité: " + this.paymentDetails.name;
    },
    selectMode(v) {
      this.selectedMode = { active: true, val: v };
    },
    handleFilterChange(on, value) {
      const other = on == "classe" ? "type" : "classe";
      let newData = this.studentList;
      if (value == "all") this.filters[on] = false;
      else {
        this.filters[on] = true;
        this.filters[`${on}_val`] = value;
        if (on == "classe")
          newData = this.studentList.filter(
            (elem) => elem.classRoom._id == value
          );
        else newData = this.studentList.filter((elem) => elem.type == value);
      }
      //handle the other filter if exists (again)
      if (this.filters[other]) {
        const temp = [...newData];
        newData = [];
        if (other == "classe")
          newData = temp.filter(
            (elem) => elem.classRoom._id == this.filters[`${other}_val`]
          );
        else
          newData = temp.filter(
            (elem) => elem.type == this.filters[`${other}_val`]
          );
      }
      this.Active_studentList = newData;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
